.bg-presence-online {
    background-color: theme("colors.green.400");
}

.bg-presence-offline {
    background-color: theme("colors.gray.200");
}

.bg-presence-dnd {
    background-color: theme("colors.red.400");
}

.bg-presence-busy {
    background-color: theme("colors.orange.400");
}

.bg-presence-away {
    background-color: theme("colors.orange.400");
}
